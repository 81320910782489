.AperturaContainerDestacado-parent { 
	position: relative;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-row: 4;
	gap: 2rem;
	min-height: 200px;
	border-bottom: 1px solid var(--shadow-gray);
	padding-bottom: 1rem;	
}

.primer-bloque {
	position: relative;
	grid-column: 1 / 3;
	grid-row: 1 / 4; 
}

.segundo-bloque {
	position: relative;
	display: grid;
    grid-column: 3 / 4;
	grid-row: 1 /2; 
	
}

.tercer-bloque {
	position: relative;
	display: grid;
    grid-column: 4 / 4;
	grid-row: 1 /2; 
}

.cuarto-bloque {
	position: relative;
	display: grid;
    grid-column: 3 / 5;
	grid-row: 2 / 3;
	padding-top: 2rem;
}

.primer-bloque::before {
	content: "";
	height: calc(100% - 4px);
	width: .0625rem;
	position: absolute;
	background-color: #e4e4e4;
	right: -1.03125rem;
}
.segundo-bloque::before {
	content: "";
	height: calc(100% - 4px);
	width: .0625rem;
	position: absolute;
	background-color: #e4e4e4;
	right: -1.03125rem;
}
.cuarto-bloque::before {
	content: "";
	height: .0625rem;
	width: calc(100% + 0rem);
	position: absolute;
	background-color: #e4e4e4;
	left: 0;
	top: 0;
	
}
@media (max-width: 1024px) {
	.AperturaContainerDestacado-parent { 
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: var(--streetSizeH);
		column-gap: 1rem;
		row-gap: 0rem;
		border-bottom: 1px solid var(--shadow-gray);
		padding-bottom: 1rem;
	}	
	.primer-bloque {
		margin-bottom: 2.2rem;
		padding: 0;
		grid-column: 1 / 5;
		grid-row: 1 / 2;
		border-bottom: 0;
	}

	.primer-bloque::before {
		display: none;
	}

	.segundo-bloque {
		padding: 0 0 0.8rem 0;
		margin: 0 ;
		grid-column: 1 / 2;
		grid-row: 2 / 3;
		border-bottom: 0;
		
	}
	.segundo-bloque::before {
		display: none;
	}

	.tercer-bloque {
		padding: 0 0 0.8rem 0;
		margin: 0;
		grid-column: 2 / 3;
        grid-row: 2 / 3;
		border-bottom: 0;
	}
	.cuarto-bloque {
		padding: 0 0 0.8rem 0;
		margin: 0;
		grid-column: 3 / 4;
		grid-row: 2 / 3;
		border-bottom: 0;
	}
	.cuarto-bloque::before {
		display: none;
	}

	}

@media (max-width: 768px) {
	.AperturaContainerDestacado-parent {
		grid-template-columns: 1fr;
		display: grid;
		border-bottom:none;
		padding-bottom: 0px;
	}

	.primer-bloque {
		margin-bottom: 2.2rem;
		padding: 0;
		grid-column: 1 / 5;
		grid-row: 1 / 2;
		border-bottom: .0625rem solid #e4e4e4;
	}
	.primer-bloque::before{
		display: none;
	}
	.segundo-bloque {
		padding: 0 0 0.8rem 0;
		margin: 0 0 2rem 0;
		grid-column: 1 / 5;
		grid-row: 2 / 3;
		border-bottom: .0625rem solid #e4e4e4;
	}

	.segundo-bloque::before{
		display: none;
	}
	.tercer-bloque {
		padding: 0 0 0.8rem 0;
		margin: 0 0 2rem 0;
		grid-column: 1 / 5;
		grid-row: 3 / 4;
		border-bottom: .0625rem solid #e4e4e4;
	}

	.cuarto-bloque {
		padding: 0 0 0.8rem 0; 
		margin: 0 0 2rem 0;
		grid-column: 1 / 5;
		grid-row: 4 / 5;
		border-bottom: .0625rem solid #e4e4e4;
	}
	
}
